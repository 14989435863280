import {useQuery} from "@apollo/client";
import {GetPagesDocument, Page, UserAction} from "generated/graphql";
import {Link} from "react-router-dom";
import {useAuth} from "hooks/useAuth";
import { VolumeList } from "./VolumeList";

interface Props {
  page: Page
}

export function ContentPage({ page }: Props) {
  const { data } = useQuery(GetPagesDocument);
  const children = data?.pages.filter(p => p.parentId === page.id && p.index > 0) ?? [];

  const { user, can } = useAuth();
  const canView = (path: string) => {
    switch (path) {
      case "editor":
        return can(UserAction.ViewArticles);
      case "assigned":
        return can(UserAction.ViewAssignedArticles);
      case "instances":
        return !!user?.workflowRole;
      case "portal":
        return !!user;
      default:
        return true;
    }
  }

  return <div>
    <p dangerouslySetInnerHTML={{ __html: page.content ?? ''}}></p>
    { children.length > 0 && <div className="gallery">
      { children.filter(d => canView(d.path)).map(d => d.path === "issue" ? <VolumeList key={d.id} /> : <div key={d.id}>
        <h3>{d.path === "instances" || d.path.endsWith(".pdf") ? <a href={d.path}>{d.name}</a> : <Link to={d.path}>{d.name}</Link>}</h3>
        <p dangerouslySetInnerHTML={{ __html: d.description ?? ''}}></p>
      </div>) }
      { window.location.hostname === "localhost" && user && <a href={`http://localhost:5173/instances/#${user.token}`}>To workflow (debug)</a> }
    </div> }
  </div>
}