import {Link, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
  AccessLevel,
  GetUserDocument,
  NotificationType,
  RefereeRequestStatus,
  ReminderType,
  UserAction
} from "generated/graphql";
import {Button, Col, Descriptions, Row, Select} from "antd";
import {DataTable} from "components/Table/DataTable";
import {formatDate, formatStatus} from "utilities/formatters";
import {useAuth} from "hooks/useAuth";
import {FileLink} from "components/Navigation/FileLink";
import {formatValue, getEntries, isClosed} from "utilities/enumHelpers";
import {DialogButton} from "components/Modal/DialogButton";
import {EditPasswordDialog} from "routes/Dialogs/EditPasswordDialog";
import {useJournal} from "hooks/useJournal";
import {RefereeReportDialog} from "routes/Editor/Dialogs/RefereeReportDialog";
import {FormDialog} from "routes/Dialogs/FormDialog";
import {ViewFormDialog} from "routes/Dialogs/ViewFormDialog";
import {EditRoleDialog} from "./Dialogs/EditRoleDialog";
import {useEditUser} from "hooks/useEditUser";
import {TextInputDialog} from "routes/Dialogs/TextInputDialog";
import {useConfirmRefereeRequest} from "hooks/useConfirmRefereeRequest";
import {EditRefereeInfoDialog} from "./Editor/Dialogs/EditRefereeInfoDialog";
import {EditEmailDialog} from "./Dialogs/EditEmailDialog";
import {EditUserNameDialog} from "routes/Editor/Dialogs/EditUserNameDialog";

const {Item} = Descriptions;
const notificationTypes = getEntries(NotificationType).map(e => ({ value: e.entry, label: e.label }));

export const UserDetail = () => {
  const { id } = useParams();

  const { data, loading, refetch } = useQuery(GetUserDocument, { variables: { id: +(id ?? '0') } });
  const user = data?.user;

  const editUser = useEditUser();

  const { user: currentUser, can } = useAuth();
  const isEditor = currentUser?.role.toLowerCase() === "editor";
  const journal = useJournal();

  const { confirm, isConfirming } = useConfirmRefereeRequest();

  return <>
    { loading && <i>Loading...</i> }
    { user && <>
        <Row>
            <Col span={12}>
                <Descriptions bordered column={1} labelStyle={{ width: "160px" }}>
                    <Item label="Full name">{user.displayName}
                      { can(UserAction.EditUserDetails) && <DialogButton dialog={props => <EditUserNameDialog {...props} user={user} />}>(edit)</DialogButton>}
                    </Item>
                    <Item label="E-mail address">{user.mailAddress}
                      { can(UserAction.EditUserDetails) && <DialogButton dialog={props => <EditEmailDialog {...props} userId={user.id} />}>(edit)</DialogButton>}
                    </Item>
                    <Item label="Password">********* { currentUser?.id === user.id && <DialogButton dialog={props => <EditPasswordDialog {...props} userId={user.id} />}>(change)</DialogButton>}</Item>
                  { can(UserAction.ManageUsers) && <Item label="Role">{formatValue(user.accessLevel)} <DialogButton dialog={props => <EditRoleDialog {...props} user={user} />}>(change)</DialogButton></Item> }
                  { can(UserAction.ViewAssignedArticles) && <Item label="Notify on">
                      <Select options={notificationTypes} mode="multiple" style={{ width: "100%" }}
                              allowClear
                              defaultValue={user.enabledNotifications}
                              onChange={v => editUser({ userId: user.id, enabledNotifications: v }) } />
                  </Item> }
                  { user.accessLevel === AccessLevel.Board && <Item label="Referee reminders">
                      <Select options={[
                        {
                          value: ReminderType.Full,
                          label: "Send automated reminders to referees"
                        },
                        {
                          value: ReminderType.EditorOnly,
                          label: "Send automated reminders only to myself"
                        },
                        {
                          value: ReminderType.None,
                          label: "Disable automated reminders"
                        }]} defaultValue={user.reminderType} style={{ width: "100%" }}
                              onChange={v => editUser({  userId: user.id, reminderType: v})} />
                  </Item> }
                  { can(UserAction.ViewUsers) && <Item label="Referee info">
                    { user.refereeInfo.comment }
                    { can(UserAction.EditUserDetails) && <DialogButton dialog={props => <EditRefereeInfoDialog {...props} userId={user.id} refereeInfo={user.refereeInfo.comment} />}>(edit)</DialogButton> }
                  </Item> }
                </Descriptions>
            </Col>
        </Row>
        <Row>
            <Col>
              <h3>Submissions</h3>
              <DataTable source={user.articles} columns={[
                {
                  key: 'Number',
                  value: a => a.number,
                  render: a =>
                    isEditor ? <Link to={`/editor/article/${a.number}`}>{journal.code} {a.number}</Link> : <>{journal.code} {a.number}</>
                },
                {
                  key: 'Title',
                  value: a => a.title,
                },
                {
                  key: 'Version',
                  value: a => a.lastVersion.version
                },
                {
                  key: 'Date submitted',
                  value: a => formatDate(a.lastVersion.dateSubmitted)
                },
                {
                  key: 'Status',
                  value: a => formatStatus(a.status)
                },
                {
                  key: 'File',
                  render: a => <FileLink file={a.lastVersion.manuscript} />
                },
                {
                  key: '',
                  render: a => <Link to={`./revision/${a.number}`}>Upload revision</Link>
                }
              ]} />

            <h3>Referee requests</h3>
            <DataTable source={user.refereeRequests} columns={[
              {
                key: 'Number',
                value: a => a.articleVersion.article.number,
                render: a => isEditor ? <Link to={`/editor/article/${a.articleVersion.article.number}`}>{journal.code} {a.articleVersion.article.number}</Link>
                  : <>{journal.code} {a.articleVersion.article.number}</>
              },
              {
                key: 'Authors',
                value: a => a.articleVersion.article.authors.map(b => b.displayName).join(', '),
              },
              {
                key: 'Title',
                value: a => a.articleVersion.article.title,
                render: a => <FileLink file={a.articleVersion.manuscript} text={a.articleVersion.article.title} />
              },
              {
                key: 'Version',
                value: a => a.articleVersion.version
              },
              {
                key: 'Date requested',
                value: a => formatDate(a.dateRequested)
              },
              {
                key: 'Deadline/submitted',
                value: a => isClosed(a.status) ? '' : formatDate(a.dateEnd)
              },
              {
                key: 'Status',
                render: a => {
                  if (a.status === RefereeRequestStatus.Sent && a.articleVersion.article.isActive) {
                    return <>
                      <Button type='link' disabled={isConfirming} onClick={() => confirm(a, true)}>Agree to referee</Button>
                      <DialogButton disabled={isConfirming}
                                    dialog={props => <TextInputDialog {...props}
                                                                      title="Decline to referee"
                                                                      text="Please could you let us know why; for example, the topic is
not a good match for your interests, you are too busy right now or you
feel the paper is highly likely to be rejected (lowest 5%). In the first
two cases, we would appreciate it if you have any suggestions for
another reviewer."
                                                                      onOk={c => confirm(a, false, c)}
                                    />}>Decline</DialogButton>
                    </>
                  }
                  return <>{formatValue(a.status)}</>;
                }
              },
              {
                key: 'Report',
                render: a => <>
                  {a.report && <FileLink file={a.report} />}
                  {!isClosed(a.status) && a.status !== RefereeRequestStatus.Completed && !a.formSubmissionId && a.articleVersion.article.isActive
                    && <DialogButton dialog={props => <RefereeReportDialog {...props} refereeRequestId={a.id} />}>Submit</DialogButton>}
                  {!isClosed(a.status) && a.status !== RefereeRequestStatus.Completed && a.formSubmissionId && a.articleVersion.article.isActive
                    && <DialogButton dialog={props => <FormDialog {...props} submissionId={a.formSubmissionId ?? 0} onComplete={refetch} />}>Submit</DialogButton>}
                  {a.status === RefereeRequestStatus.Completed && a.formSubmissionId
                    && <DialogButton dialog={props => <ViewFormDialog {...props} submissionId={a.formSubmissionId ?? 0} />}>View</DialogButton> }
                </>
              }
            ]} />
        </Col>
        </Row>
    </> }
  </>
}